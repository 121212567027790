import { Component, OnInit } from '@angular/core';
import { PdpDayWiseResupplyService } from './pdp-day-wise-resupply.service';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pdp-day-wise-resupply',
  templateUrl: './pdp-day-wise-resupply.component.html',
  styleUrls: ['./pdp-day-wise-resupply.component.css']
})
export class PdpDayWiseResupplyComponent implements OnInit {
  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;

  searchForm: FormGroup;
  userCode: string;
  searchResults: any[] = [];
  responseMessage: string = '';
  editingRowIndex: number | null = null;
  searchPerformed: boolean = false;
  currentPage: number = 1;
  itemsPerPage: number = 10; 
  originalData: any = {}

  constructor(
    private fb: FormBuilder,
    private pdpservice: PdpDayWiseResupplyService
    
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.buildForm();
  }

  ngOnInit() {
  }

  buildForm(): void {
    this.searchForm = this.fb.group({
      shopCode: ['',[Validators.pattern(/^\d*$/)]],
      rdcCode: ['',[Validators.pattern(/^\d*$/)]],
      userCode: [this.userCode],
    });
  }

  onReset() {
    this.searchForm.reset();
    this.responseMessage = '';
    this.searchResults = [];
    this.searchPerformed = false;
    this.editingRowIndex = null;
  }

  toggleEdit(index: number) {
    const absoluteIndex = (this.currentPage - 1) * this.itemsPerPage + index;
  
    if (this.editingRowIndex === absoluteIndex) {
      this.searchResults[absoluteIndex] = JSON.parse(JSON.stringify(this.originalData));
      this.editingRowIndex = null;
    } else {
      this.originalData = JSON.parse(JSON.stringify(this.searchResults[absoluteIndex]));
      this.editingRowIndex = absoluteIndex;
    }
  }

  saveChanges() {
    
    const editedData = this.searchResults[this.editingRowIndex];
    for (let key in editedData) {
      if (typeof editedData[key] === 'string' && editedData[key] === 'null') {
        editedData[key] = null;
      }
    }
    this.pdpservice.saveEditedRow(editedData).subscribe(
      (response) => {
        this.editingRowIndex = null;
        alert('Changes saved successfully!');
      },
      (error) => {
        alert('Failed to save changes.');
      }
    );
  }

  onSearch() {
    this.currentPage = 1;
    this.editingRowIndex = null;
    const shopCode = this.searchForm.value.shopCode; 
    const rdcCode = this.searchForm.value.rdcCode;
  
    const payload: any = {
      shopCode: '',
      rdcCode: ''
    };

      payload.shopCode = shopCode ? shopCode : '';
    
      payload.rdcCode = rdcCode ? rdcCode : '';
    
    this.pdpservice.getPDPDayWiseResupply(payload).subscribe(
      (response: any) => {
        if (response.dataList && response.dataList.length > 0) {
          this.searchResults = response.dataList; 
          this.responseMessage = ''; 
        } else {
          this.searchResults = []; 
          this.responseMessage = 'No Data Found';
        }
      },
      (error) => {
        this.responseMessage = 'Error fetching data.';
      }
    );
  }
}