import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
@Injectable({
    providedIn:'root'
})
export class PdpDayWiseResupplyService {
  
urlPdp: string = BilApiUrl.PDP_RESUPPLY;
urlUpdateDays: string = BilApiUrl.PDP_RESUPPLY_UPDATE_DAYS;
constructor(private _http: HttpClient) {}
getPDPDayWiseResupply(details){
  return this._http.post(this.urlPdp, details);
}
saveEditedRow(data){
  return this._http.post(this.urlUpdateDays, data);
}
}