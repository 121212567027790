import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteMasterService } from '../route-master/route-master.service';
import { ResupplyReleaseService } from './resupply-release.service';
@Component({
  selector: 'app-resupply-release',
  templateUrl: './resupply-release.component.html',
  styleUrls: ['./resupply-release.component.css']
})
export class ResupplyReleaseComponent implements OnInit {
 @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  searchForm: FormGroup;
  userCode: string;
  searchResults: any[] = [];
  responseMessage: string = '';
  editingRowIndex: number | null = null;
  searchPerformed: boolean = false;
  currentPage: number = 1;
  itemsPerPage: number = 10; 
  originalData: any = {}
  userName: string;
  checked:Boolean = false
  concepts: any;
  ShopCodes: any[] = [];
  routes: string[] = []; // All routes
  subRoutes: string[] = []; // Filtered subroutes
  destCodes: string[] = []; // Filtered dest codes
  selectedRoute: string | null = null; // Selected route
  selectedSubRoute: string | null = null; // Selected subroute
  payload: any = {}; // Store the last used search parameters
  constructor(
    private fb: FormBuilder,
    private routeMasterService: RouteMasterService,
    private resupplyReleaseService:ResupplyReleaseService
    
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.buildForm();
  }
  ngOnInit(): void {
    this.fetchRoutes();
    const sessUsername = sessionStorage.getItem('currentUser');
    if (sessUsername) {
      this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
      const conceptQueryMas = [{ name: 'email', value: this.userName }];
  
      this.routeMasterService.getConcepts(conceptQueryMas).subscribe(
        (data: any) => {
          this.concepts = data.dataList;
          console.log(this.concepts);
          this.loaDer.nativeElement.style.display = 'none';
        },
        (error) => {
          console.error('Failed to fetch concepts:', error);
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
    
  }  
  buildForm(): void {
    this.searchForm = this.fb.group({
      concept: [""],
      route: [""],
      subRoute: [""],
      destShopNumber: [""],
      resupplyNo: [""]
    });
  }
  fetchRoutes(): void {
    this.resupplyReleaseService.getRoute().subscribe((data: any) => {
      this.routes = data; // Assign the data to the routes array
    });
  }
  onRouteChange(): void {
    const selectedRoute = this.searchForm.get('route')?.value; // Fetch value from the form
    if (selectedRoute) {
      // Fetch subroutes
      this.resupplyReleaseService.getSubRoute(selectedRoute).subscribe((data: string[]) => {
        console.log("Subroutes fetched:", data);
        this.subRoutes = data;
        this.searchForm.get('subRoute')?.reset(); // Clear subroute
        this.destCodes = []; // Reset destination codes
      });
  
      // Fetch destination codes
      this.resupplyReleaseService.getDestCode(selectedRoute).subscribe((data: string[]) => {
        console.log("Destination codes fetched:", data);
        this.destCodes = data;
      });
    } else {
      // Reset data
      this.subRoutes = [];
      this.destCodes = [];
    }
  }
    
  onReset() {
    this.searchForm.reset();
    this.responseMessage = '';
    this.searchResults = [];
    this.searchPerformed = false;
    this.editingRowIndex = null;
    this.payload = null;
  }
  getCurrentPageRows(): any[] {
    const itemsPerPage = 10; 
    const startIndex = (this.currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    return this.searchResults.slice(startIndex, endIndex);
  }
  isAllCheckBoxChecked(): boolean {
    const currentPageRows = this.getCurrentPageRows();
    return currentPageRows.every((row) => row.isSelected);
  }
  
  selectAllRows(event?: Event): void {
  const currentPageRows = this.getCurrentPageRows();
  const isChecked = event ? (event.target as HTMLInputElement).checked : true;
  
  currentPageRows.forEach((row) => {
    row.isSelected = isChecked;
  });
}
  areAllRowsSelected(): boolean {
    const currentPageRows = this.getCurrentPageRows();
    return currentPageRows.every((item) => item.isSelected);
  }
  
  areAnyRowsSelected(): boolean {
    const currentPageRows = this.getCurrentPageRows();
    return currentPageRows.some((row) => row.isSelected);
  }
  releaseSelectedRows(): void {
    // Extract only the selected rows
    const selectedRows = this.searchResults.filter((item) => item.isSelected);
  
    if (selectedRows.length > 0) {
      // Map to extract only the `itnRsnSerialNumber`
      const payload = selectedRows.map((item) => ({
        itnRsnSerialNumber: item.itnRsnSerialNumber
      }));
  
      this.resupplyReleaseService.saveApi(payload).subscribe(
        (response) => {
          console.log('Payload sent:', payload); // Debugging purposes
          console.log('Response:', response);
          alert('Selected rows saved successfully!');
          this.onSearch();
        },
        (error) => {
          console.error('Error saving selected rows:', error);
          alert('Failed to save selected rows.');
        }
      );
    } else {
      alert('No rows selected to save.');
    }
  }  
  onSearch() {
    this.currentPage = 1;
    this.editingRowIndex = null;
    console.log("form data", this.searchForm.value); 
    // Constructing the payload
    this.payload = {
        destShopNumber: this.searchForm.value.destShopNumber || "",
        route: this.searchForm.value.route || "",
        subRoute: this.searchForm.value.subRoute || "",
        concept: this.searchForm.value.concept || "",
        resupplyNo: this.searchForm.value.resupplyNo || ""
    };
    
    // Calling the service to fetch data
    this.resupplyReleaseService.getResupplyRelease(this.payload).subscribe(
        (response: any) => {
            if (response.dataList && response.dataList.length > 0) {
                // Adding isSelected property to each item
                this.searchResults = response.dataList.map((item: any) => ({
                    ...item,
                    isSelected: false, // Set default to false
                }));
                this.responseMessage = ''; 
            } else {
                this.searchResults = []; 
                this.responseMessage = 'No Data Found';
            }
        },
        (error) => {
            this.responseMessage = 'Error fetching data.';
        }
    );
}
}
