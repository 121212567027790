import { Component } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user';
import { Role } from './models/role';
import {BilApiUrl} from './common/bil-api-url';
import {VersionCheckService} from './common/version-check.service';
import { NavigationLinkParameterService } from './common/navigation-link-parameters-service';
// import { OAuthService} from 'angular-oauth2-oidc';
// import { authCodeFlowConfig } from './sso.config';

import { from } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //styleUrls: ['./app.component.css']
  styleUrls: ['../assets/css/bootstrap.css', '../assets/css/bil-style.css']
})
export class AppComponent {
  title = 'BilScmSystem';
  currentUser: User;
  userName: string;
  userRole: string;
  roleArray:any;
  coCode:any;
  userModule:string;
  IT:boolean=false;
  Ordering:boolean=false;
  HOD:boolean=false;
  OTB:boolean=false;
  Merchandising:boolean=false;
  Planning:boolean=false;
  Sourcing:boolean=false;
  Export:boolean=false;
  Vendor:boolean=false;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private versionCheckService: VersionCheckService,private _navigationLinkParameterService: NavigationLinkParameterService,
        private _router: Router) {
            console.log('check for localhost call');
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.router.events
        .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
        .subscribe(event => {
        if (
            event.id === 1 &&
            event.url === event.urlAfterRedirects
        ) {
        // ..... // here your code when page is refresh
            console.log('Browser refreshed');
            this._router.navigate(['']);
        }
        })
    }

    get isAdmin() {
        return this.currentUser && this.currentUser.role === Role.Admin;
    }

    ngOnInit() {
        // let sessUsername = sessionStorage.getItem('currentUser');
        // console.log(JSON.parse(sessUsername)[0].userName);
        console.log('AppComponents called');
        this.authenticationService.getLoggedInName.subscribe(name => this.changeName(name));
        this.authenticationService.getLoggedInRole.subscribe(role => this.changeRole(role));
        this.authenticationService.getLoggedInConcept.subscribe(conc => this.changeConcept(conc));
        this.authenticationService.getLoggedInModule.subscribe(umodule => this.changeModule(umodule));
        this.versionCheckService.initVersionCheck(BilApiUrl.versionCheckURL);

        this.router.events
        .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
        .subscribe(event => {
        if (
            event.id === 1 &&
            event.url === event.urlAfterRedirects
        ) {
        // ..... // here your code when page is refresh
            console.log('Browser refreshed');
            this._router.navigate(['']);
        }
        });
    }

    private changeConcept(name: string): void {
        this.coCode = name;
        //  console.log(this.coCode);
    }

    private changeName(name: string): void {
        this.userName = name;
        // console.log(this.userName);
    }

    private changeRole(role: string): void {
        this.userRole = role;2
        if(this.userRole.indexOf('Ordering')>=0){
            this.Ordering = true;
        }
        if(this.userRole.indexOf('IT')>=0){
            this.IT = true;
        }
        if(this.userRole.indexOf('HOD')>=0){
            this.HOD = true;
        }
        if(this.userRole.indexOf('OTB')>=0){
            this.OTB = true;
        }
        if(this.userRole.indexOf('Merchandising')>=0){
            this.Merchandising = true;
        }
        if(this.userRole.indexOf('Planning')>=0){
            this.Planning = true;
        }
        if(this.userRole.indexOf('Sourcing')>=0){
            this.Sourcing = true;
        }
        if(this.userRole.indexOf('Export')>=0){
            this.Export = true;
        }
        if(this.userRole.indexOf('Vendor')>=0){
            this.Vendor = true;
        }


        this.roleArray = [];
        this.roleArray = this.userRole.split(',');
    }
    private changeModule(umodule: string): void {
        this.userModule = umodule;
        // this.userModule='RDS';
        // console.log(this.userModule);
    }

    logout() {
        this.authenticationService.logout();
        // this.router.navigate(['/login']);
        // this.router.navigate(['/logout']);
    }

    searchPoMenuClick(){
        this._navigationLinkParameterService.searchPOHeaderData = undefined;
        this._router.navigate(['search-po']);
    }

    pendingPoMenuClick(){
        this._navigationLinkParameterService.pendingPoSearchData = undefined;
        this._router.navigate(['pending-po']);
    }

    searchInvoiceMenuClick(){
        this._navigationLinkParameterService.searchInvoiceHeaderData = undefined;
        this._router.navigate(['rds-search-invoice']);
    }

    searchASNMenuClick(){
        this._navigationLinkParameterService.searchasnHeaderData = undefined;
        this._router.navigate(['rds-asn-search']);
    }

    searchReqMenuClick(){
        this._navigationLinkParameterService.searchHeaderData = undefined;
        this._router.navigate(['search-requisition']);
    }
    searchGRNMenuClick(){
        this._navigationLinkParameterService.searchGRNHeaderData = undefined;
        this._router.navigate(['rds-search-grn']);
    }
}
