import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ResupplyReleaseService {

  urlResupply: string = BilApiUrl.RESUPPLY_RELEASE;
  urlResupplyUpdate: string = BilApiUrl.RESUPPLY_RELEASE_UPDATE;
  urlResupplyRoute: string = BilApiUrl.RESUPPLY_RELEASE_ROUTE;
  urlResupplySubRoute: string = BilApiUrl.RESUPPLY_RELEASE_SUB_ROUTE;
  urlResupplyDestCode: string = BilApiUrl.RESUPPLY_RELEASE_DEST_CODE;

  constructor(private _http: HttpClient) { }

  getResupplyRelease(details) {
    return this._http.post(this.urlResupply, details);
  }

  saveApi(details) {
    return this._http.post(this.urlResupplyUpdate, details);
  }

  getRoute(): Observable<any> {
    console.log("route data in this", this._http.get(this.urlResupplyRoute))
    return this._http.get(this.urlResupplyRoute);
  }

  getSubRoute(route: string): Observable<any> {
    const url = `${this.urlResupplySubRoute}?route=${route}`;
    console.log("sub data in this", this._http.get(url))
    return this._http.get(url);
  }

  getDestCode(route: string): Observable<any> {
    const url = `${this.urlResupplyDestCode}?route=${route}`;
    console.log("dest data in this", this._http.get(url))
    return this._http.get(url);
  }

}
